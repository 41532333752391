import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
  },
  getters: {
  },
  mutations: {
    GET_CONFIG_APPLICATION(state, payload) {
      state.respData = payload
      
    },
    SWITCH_CHANGE(state, item) {
      const nameChild = item.name.split('.')
      
      if (nameChild.length > 1) {
        state.respData[nameChild[0]][nameChild[1]] = item.status
      } else {
        state.respData[item.name] = item.status
      }
    },
    INPUT_CHANGE(state, item) {
      const nameChild = item.name.split('.')
      
      if (nameChild.length > 1) {
        state.respData[nameChild[0]][nameChild[1]] = item.value
      } else {
        state.respData[item.name] = item.value
      }
    },
    UPDATE(state, payload) {
      state.respData = payload
      
    },
  },
  actions: {
    get({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/web/application/eCommerce/system/get')
          .then(response => {
            
            commit('GET_CONFIG_APPLICATION', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    inputChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/system/inputChange', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    switchChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/system/switchChange', item)
          .then(response => {
            commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
